import React from 'react';
import './App.css';
import Navbar from './components/layouts/navbar';
import Home from './components/pages/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Services from './components/pages/services';
import Products from './components/pages/products';
import AdminPanel from './components/pages/adminpanel';
import Preview from './components/layouts/preview';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/wsx' element={<AdminPanel />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;