/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrders = /* GraphQL */ `
  query GetOrders($id: ID!) {
    getOrders(id: $id) {
      id
      received
      toSend
      rate
      status
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        received
        toSend
        rate
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const customList = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
      }
      nextToken
    }
  }
`;
export const getRate = /* GraphQL */ `
  query GetRate($id: ID!) {
    getRate(id: $id) {
      id
      rate
      createdAt
      updatedAt
    }
  }
`;
export const listRates = /* GraphQL */ `
  query ListRates(
    $filter: ModelRateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByDate = /* GraphQL */ `
  query OrdersByDate(
    $id: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrdersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByDate(
      id: $id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        received
        toSend
        rate
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
