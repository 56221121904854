import React, { useState, useEffect } from 'react';


function Rates({rates, updateRate, Rate2Edit}) {
    let fetchedStatus = 'NA';
    let initialID = 'NA';
    let [rateID, setRateID] = useState(initialID);
    let [rate, setRate] = useState(fetchedStatus);

    let [newRate, setNewRate] = useState();

    function submitUpdate() {
        setRateID(rateID);
        //console.log("newRate is now: " + newRate);
        //console.log("Rate2Edit is now: " + Rate2Edit);
        updateRate(rateID, newRate, Rate2Edit);
    }

    function handleChange(e) {
        setNewRate(e.target.value);
    }

    return(
        <>
        <table>
            <tbody>
                <tr>
                    <th>Conversion</th>
                    <th>Rate</th>
                    </tr>
                    {
                        rates.map((rate, index) => (
                            <tr key={index}>
                                <td>{rate.id}</td>
                                <td><input id={rate.id} name={rate.id} key={rate.id} defaultValue={rate.rate} onChange={handleChange}></input></td>
                                <td><button onClick={() => {
                                    Rate2Edit = rate.id;
                                    //console.log("Rate2Edit is: " + Rate2Edit);
                                    submitUpdate();
                                }}>Update</button></td>
                            </tr>
                        ))
                    }
            </tbody>
        </table>
        </>
    );
}

export default Rates;