import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listOrders } from '../../graphql/queries';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { Authenticator } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import '../../css/adminpanel.css';
import Orders from '../layouts/orders';
import Rates from '../layouts/rates';

function AdminPanelRender() {
    const [orders, setOrders] = useState([]);
    const [rates, setRates] = useState([]);
    let fetchedStatus = 'NA';
    const [status, setStatus] = useState(fetchedStatus);
    
    let initialID = 'NA';
    const [ID, setID] = useState(initialID);

    let ID2Edit = '';
    let rate2Update = '';
    
    useEffect(() => {
        fetchOrders();
        fetchRate();
    }, [])

    async function fetchRate() {
        try {
            const ratesData = await API.graphql(graphqlOperation(queries.listRates));
            const rates = ratesData.data.listRates.items;
            setRates(rates);

            // DEBUG INFO
            //console.log('done fetching rate...');
        }
        catch (err) {
            // DEBUG INFO
            //console.log('error fetching rate...');
        }
    }

    async function updateRate() {
        try {
            const rate = { id: 'USDTTOMYR', rate: '5.5'};
            // This is the call to create the order
            await API.graphql(graphqlOperation(mutations.updateRate, {input: rate}));
        }
        catch (err) {
            // DEBUG INFO
            //console.log('error updating rate...');
        }
    }

    async function fetchOrders() {
        try {
            const queryParams = {
                sortDirection: 'DESC'
            };
            const orderData = await API.graphql(graphqlOperation(listOrders));
            //const orderData = await API.graphql(graphqlOperation(queries.ordersByDate, queryParams));
            const orders = orderData.data.listOrders.items;
            setOrders(orders);
            // DEBUG INFO
            //console.log('done fetching orders...');
        }
        catch (err) {
            // DEBUG INFO
            //console.error('error fetching orders...' + err);
        }
    }

    // This function will perform the POST to update the status
    async function updateStatus(e, newStatus, ID2Edit) {
        try {
            // Enumerate the existing orders and find the one we are going to update
            // The ID to edit comes from the user, from orders.js
            let enumOrders = orders.map (order => {
                
                // If its a match, construct the order to update and fire away
                if (order.id === ID2Edit) {
                    //const updatedOrder = { id: ID2Edit, status: newStatus};
                    //await API.graphql({ query: mutations.updateOrders, variables: {input: updatedOrder}});
                }
                return order
            });
            const updatedOrder = { id: ID2Edit, status: newStatus};
            await API.graphql({ query: mutations.updateOrders, variables: {input: updatedOrder}});

            alert('Status update success...');
        } catch (err) {
            // DEBUG INFO
            //console.log('error updating order: ' + err);
            alert('Status update failed...');
        }
    }

    // This function will perform the POST to update the rate
    async function updateRate(e, newRate, rate2Update) {
        try {
            const updatedRate = { id:rate2Update, rate: newRate };
            await API.graphql({ query: mutations.updateRate, variables: {input: updatedRate}});
            alert('Rate update success...');
        } catch (err) {
            // DEBUG INFO
            //console.log('error updating rate: ' + err);
            alert('Rate update failed...');
        }
    }

    const styles = {
        container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
        order: {  marginBottom: 15 },
        input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
        orderName: { fontSize: 20, fontWeight: 'bold' },
        todoDescription: { marginBottom: 0 },
        button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
      }

    return(
        <>
        <Authenticator className='loginCard'>
            {({ signOut, user }) => (
                <div className='account-head-div'>
                <h1>Hello {user.attributes.email.split("@", 1)}</h1>
                <button onClick={signOut}>Sign out</button>
                </div>
            )}
        </Authenticator>

        <p>This is the admin panel</p>

        <Rates
        rates = {rates}
        updateRate = {updateRate}
        rate2Update = {rate2Update}
        />
        
        <Orders
        orders = {orders}
        updateStatus = {updateStatus}
        ID2Edit = {ID2Edit}
        />
        
        </>
    );
}

export default withAuthenticator(AdminPanelRender, { hideSignUp: true });
