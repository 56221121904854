import React, { useState, useEffect } from 'react';
import '../../css/adminpanel.css';

function Orders({orders, updateStatus, ID2Edit}) {
    let fetchedStatus = 'NA';
    let initialID = 'NA';
    let [editID, setEditID] = useState(initialID);
    const [status, setStatus] = useState(fetchedStatus);
    

    function submitUpdate() {
        updateStatus(editID, status, ID2Edit);
        setEditID(editID);
    }

    function handleChange(event) {
        setStatus(event.target.value);
      }

    return(
        <>
        <table>
            <tbody>
                <tr>
                    <th>Reference</th>
                    <th>Created At</th>
                    <th>Rate</th>
                    <th>Received</th>
                    <th>To Send</th>
                    <th>Status</th>
                    </tr>
                    {
                        orders.map((order, index) => (
                            <tr key={index}>
                                <td><input id={order.id} key={order.id} value={order.id} readOnly></input></td>
                                <td>{order.createdAt}</td>
                                <td>{order.rate}</td>
                                <td>{order.received}</td>
                                <td>{order.toSend}</td>
                                <td>
                                    <select name='statusSelection' value={status.value} onChange={handleChange}>
                                        <option value={order.status}>{order.status}</option>
                                        <option value='Pending'>Pending</option>
                                        <option value='Done'>Done</option>
                                        <option value='Cancelled'>Cancelled</option>
                                    </select>
                                </td>
                                <td><button onClick={() => {
                                    ID2Edit = order.id;
                                    //setEditID(order.id);
                                    submitUpdate();
                                }}>Update</button></td>
                            </tr>
                        ))
                    }
            </tbody>
        </table>
        </>
    );
}

export default Orders;