import React from 'react';
import '../../App.css';
import Cards from '../layouts/cards';
import HeroSection from '../layouts/herosection';
import Footer from '../layouts/footer';

function Home() {
  return (
    <>
    <Cards />
    </>
  );
}

export default Home;