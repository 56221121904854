/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrders = /* GraphQL */ `
  mutation CreateOrders(
    $input: CreateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    createOrders(input: $input, condition: $condition) {
      id
      received
      toSend
      rate
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateOrders = /* GraphQL */ `
  mutation UpdateOrders(
    $input: UpdateOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    updateOrders(input: $input, condition: $condition) {
      id
      received
      toSend
      rate
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrders = /* GraphQL */ `
  mutation DeleteOrders(
    $input: DeleteOrdersInput!
    $condition: ModelOrdersConditionInput
  ) {
    deleteOrders(input: $input, condition: $condition) {
      id
      received
      toSend
      rate
      status
      createdAt
      updatedAt
    }
  }
`;
export const createRate = /* GraphQL */ `
  mutation CreateRate(
    $input: CreateRateInput!
    $condition: ModelRateConditionInput
  ) {
    createRate(input: $input, condition: $condition) {
      id
      rate
      createdAt
      updatedAt
    }
  }
`;
export const updateRate = /* GraphQL */ `
  mutation UpdateRate(
    $input: UpdateRateInput!
    $condition: ModelRateConditionInput
  ) {
    updateRate(input: $input, condition: $condition) {
      id
      rate
      createdAt
      updatedAt
    }
  }
`;
export const deleteRate = /* GraphQL */ `
  mutation DeleteRate(
    $input: DeleteRateInput!
    $condition: ModelRateConditionInput
  ) {
    deleteRate(input: $input, condition: $condition) {
      id
      rate
      createdAt
      updatedAt
    }
  }
`;
