import React, { useState} from 'react';
import '../../css/cards.css';
import CalculatorItem from './calculator';

function Cards() {
  let [transferAmount, setTransferAmount] = useState("0");

  const regex4Empty = /^\s*$/;

  function renderPreview(fromInput) {
    // Do the arithmatic conversation here and pass it into the PreviewItem child component
    // If input is empty, return nothing
    if(regex4Empty.test(fromInput.text)) {
      return;
    }

    // Otherwise, do the following
    else {
      // DEBUG INFO
      //console.log("In cards.js " + fromInput.text);
    }
    setTransferAmount(fromInput.text);
  }

  return (
    <div className='cards'>
      <h1>Cashout your USDT !</h1>
      <h3>ANNOUNCEMENT: Operation Hours are from 1 PM - 7 PM, Monday to Friday ONLY. Trade outside operation hours is not refundable! Take note!</h3>
      <div className='cards__container'>
            <h4>🚀Step 1: Enter the amount you wish to cashout🚀</h4>
            <CalculatorItem
            onSubmit={renderPreview}
            />

            <h4>🚀Step 2: Send {transferAmount} USDT (TRC-20) to ONLY this wallet address below🚀</h4>
            <p>TBAQ5HmRHUD2sHofUi3nB6JRrqfBBwTuHR</p>

            <h4>🚀Step 3: After the transfer is complete, take a screenshot and send it to this WhatsApp number +60124351005🚀</h4>
            <p>Please also provide your Full Name, Bank Name, Bank Account number and reference ID</p>

            <div className='tnc_container'>
            <p><b>❗Terms & conditions❗</b></p>
            <p>We provide buy USDT service, it is Seller own responsibility after selling for whatever their purpose.</p>
            <p>Please read before selling:-</p>
            <h5>✅ Ensure your wallet address is not blacklisted on any blockchain </h5>
            <h5>✅ Receive ONLY with your own account. ONLY Instant Transfer</h5>
            <h5>✅ We accept USDT on TRC-20 ONLY</h5>
            <h5>❌NO other Third Party Name Transfer </h5>
            <h5>❌NO transfer outside of operating hours. The operating hours is from 1PM to 7PM</h5>
            <h5>Note:- Penalty of 100% will be imposed for those violating the terms and conditions above. Thus, the full amount will not be refunded.</h5>
            <h5>Once again please take note of the terms & conditions.</h5>
            </div>
        </div>
      </div>
  );
}

export default Cards;