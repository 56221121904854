import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { createOrders } from '../../graphql/mutations';
import { customList } from '../../graphql/queries';
import { listRates } from '../../graphql/queries';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/calculator.css';

function CalculatorItem(props) {
  let [fromInput, setFromInput] = useState('');
  let [toInput, setToInput] = useState('');
  let [buttonText, setButtonText] = useState('Preview Conversion');
  let rateToUse = 4.5;
  let uniqRef = '';
  const regex4Empty = /^\s*$/;
  const regex4Malicious = /[a-z]|[A-Z]|[|!@#$%^&*()_+\-\\\[\]=\|<>?`~±§£™\ ]/g;
  const minOrder = '100.00';

  let transferAmount = 0;
  const rate = 4.2;
  const serviceCharge = 10;
  const initialStatus = 'Pending';

  const initialState = { id: '', received: '', toSend: '', rate: rateToUse, status: initialStatus }
  const [formState, setFormState] = useState(initialState);
  const [orders, setOrders] = useState('');

  let toastSuccessMessage = '';
  let toastWarningMessage = 'Minimum order is 100 USDT. Please re-enter amount below.';
  let toastErrorMessage = 'Order creation failed. Please refresh the page and try again.';
  
  useEffect(() => {
    fetchOrders();
  }, [])

  const successToast = () => {
    toast.success(toastSuccessMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const warningToast = () => {
    setToInput('');
    setFromInput('');
    setButtonText('Preview Conversion');

    toast.warning(toastWarningMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  const errorToast = () => {
    setToInput('');
    setFromInput('');
    setButtonText('Preview Conversion');

    toast.error(toastErrorMessage, {
      position: toast.POSITION.TOP_CENTER,
      closeOnClick: false,
      autoClose: false
  });
  }

  function handleFromChange(e) {
    setFromInput(e.target.value);
    setButtonText("Preview Conversion");
  }

  function IDExists(IDNum) {
    return orders.some(function(x) {
      return x.id === IDNum;
    }); 
  }

  function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function isMalicious(validateThis) {
    if (regex4Malicious.test(validateThis)) {
      errorToast();

      // DEBUG INFO
      //console.log(validateThis + ' is NOT VALID');
      //return false;
      return true;
    }

    return false;
  }

  function generateUniqueID() {
    // Generate the ID and chuck it into the IDExists function to check whether it exists
    let id = 'UNIQUEID';

    // If return true means it exists
    while(IDExists(id)) {
      id = makeid(6);
    }

    return id;
  }

  // This will clear up some confusion
  // fromInput = Value users see, what they are trying to convert
  // toInput = Value users see, what they are trying to get from us
  // received = Value admins see, what we received from users - this should be the same as the fromInput value
  // toSend = Value admins see, what we need to send to users - this should be the same the toInput value
  async function fetchOrders() {
    try {
        const orderData = await API.graphql(graphqlOperation(customList));
        const orders = orderData.data.listOrders.items;
        setOrders(orders);
        // DEBUG INFO
        //console.log(orderData);
        //console.log('done fetch orders...');
      }
    catch (err) {
      errorToast();
      // DEBUG INFO
      //console.log('error fetching orders...');
    }
}

  async function createOrder(id, fromInput_Float, toInput_Float) {
    try {
      if ( !id  || !fromInput_Float || !toInput_Float || !rateToUse || !initialStatus) {
        // DEBUG INFO
        //console.log("SOME VALUES ARE MISSING !!");
        return
      }

      // Sanitize fromInput_Float and toInput_Float values for malicious input
      // Sanitize fromInput_Float and toInput_Float values to ensure they are not String or has special characters
      else if (isMalicious(fromInput_Float) || isMalicious(toInput_Float)) {
        // DEBUG INFO
        //console.log('these values are not expected input');
        return
      }
      const uniqRef = generateUniqueID();

      // DEBUG INFO
      //console.log('uniqRef is now: ' + uniqRef);
      //console.log('fromInput_Float is now: ' + fromInput_Float);
      //console.log('toInput_Float is now: ' + toInput_Float);
      //console.log('rateToUse is now: ' + rateToUse);
      //console.log('initialStatus is now: ' + initialStatus);

      const order = { id: uniqRef, received: fromInput_Float + " USDT", toSend: toInput_Float + " MYR", rate: rateToUse, status: initialStatus};
      setOrders([orders, order]);
      setFormState(initialState);

      // This is the call to create the order
      await API.graphql(graphqlOperation(createOrders, {input: order}));

      // Refresh the available orders
      fetchOrders();

      // Set the success message with unique ID and fire it off
      toastSuccessMessage = 'Order placed successfully. Please proceed to next steps with your reference ID: ' + uniqRef;
      successToast();
    } catch (err) {
      // DEBUG INFO
      //console.log('error creating order: ' + err);
      errorToast();
    }
  }

  async function fetchRate(fromToken, toToken) {
    //Fetch rate from backend and set rateToUse here
    try {
      const matchingConversion = fromToken + '-' + toToken;
      const ratesData = await API.graphql(graphqlOperation(listRates));
      const rates = ratesData.data.listRates.items;

      // Enum the fetched rates to match and use the rate
      rates.map((rate) => {
        if (rate.id === matchingConversion) {
          rateToUse = rate.rate;
          return rate.rate;
        }
      });
      // DEBUG INFO
      //console.log('Using this rate: ' + rateToUse);
    }
    catch (err) {
      // DEBUG INFO
      //console.log('error fetching rate...');
  }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setButtonText("Submit Order");

    // Do preview conversion stuff here
    if (buttonText === "Preview Conversion") {
      // Use the .then below, if not, fetchRate will not return in time before 
      fetchRate('USDT', 'MYR').then(() => 
          setToInput(parseFloat(fromInput * rateToUse).toFixed(2))
      );
    }

    // Do operations to submit order here, including security checks for price tampering
    else if (buttonText === "Submit Order") {
      // Adding serviceCharge to the fromInput
      transferAmount = parseFloat(fromInput) + parseFloat(serviceCharge);
      let transferAmount_Float = parseFloat(transferAmount).toFixed(2);

      // Update the rate first before continue
      fetchRate('USDT', 'MYR').then(() => {
        // Converting the values before comparison for error checking
      let toInput_Float = parseFloat(toInput).toFixed(2);
      let fromInput_Float = parseFloat(fromInput).toFixed(2);
      let rate_Float = parseFloat(rateToUse).toFixed(2);

      // DEBUG INFO
      //console.log('toInput_Float is now: ' + toInput_Float);
      //console.log('rateToUse is now: ' + rateToUse);
      //console.log('rate_Float is now: ' + rate_Float);
      //console.log('fromInput_Float is now: ' + fromInput_Float);
      //console.log('transferAmount is now: ' + transferAmount);
      //console.log('transferAmount_Float is now: ' + transferAmount_Float);
      
      // Some error checking to prevent price tampering
      // Starting with the positive test...
      if ((toInput_Float/rate_Float).toFixed(2) === fromInput_Float) {
        // Pass the values into createOrder for order creation
        createOrder('UNIQUEID', transferAmount_Float, toInput_Float);

        // Clear the values
        setFromInput('');
        setToInput('');
        setButtonText('Preview Conversion');
      }

      // Don't do the order creation if negative test
      else {
        errorToast();
        // DEBUG INFO
        //console.log("order creation failed, parameter tempering detected...");
      }
      });
      
    }

    // We are only expecting 2 values, if this is altered, it means users are tampering with the value
    else {
      // DEBUG INFO
      //console.log("Something dodgy is going on");
    }

    // The below block of code will return values back to parent component
    props.onSubmit({
      text: transferAmount
    });
  }

  return (
    <>
    <ToastContainer
    newestOnTop
    />
        <form onSubmit={handleSubmit}>
          <div className='calculator_body_container'>
          <span>From: </span>

          <label>
            <select id='token'>
              <option value="USDT">USDT</option>
            </select>
            
            <span>To: </span>
              <select id="fiat">
                <option value="MYR">MYR</option>
              </select>
            </label>
            </div>

            <div className='calculator_input_container'>
            <span>USDT to cashout</span>
            <input
              type='text'
              name='fromInput'
              value={fromInput}
              onChange={handleFromChange}
            />
            </div>

            <div className='calculator_input_container'>
            <span>MYR to receive</span>
            <input
              type='text'
              name='toInput'
              value={toInput}
              disabled
            />
            </div>
            

          <div className='calculator_input_container'>
            <button>{buttonText}</button>
          </div>

        </form>
        </>
  );
}

export default CalculatorItem;